<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable camelcase -->
<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import useTestnet from '~/composables/useTestnet'

interface Playermon {
  playermon_id: number
  price: number
  image: string
  class: string
  head?: string
  tail?: string
  body?: string
  arm?: string
  eye?: string
  chest?: string
  leg?: string
  timestamp?: number
}

interface MarketplaceResponseApi {
  status: number
  data: {
    marketplace_items: Playermon[]
    total_pages: number
    total_items: number
  } | null
}

const volume = ref<string | null>(null)
const totalSales = ref<string | null>(null)
const floorPrice = ref<string | null>(null)
const totalSupply = ref<string | null>(null)
const totalOwners = ref<string | null>(null)
const uniqueOwnersPercentage = ref<string | null>(null)
const selectedPeriod = ref('all')
const page = ref<number>(1)
const limit = ref<number>(10)
const keyword = ref<string>('')

const {
  apiUrl,
  imageUrl
} = useTestnet()

// Define a ref variable to store the recent sales data
const recentSales = ref<any[]>([])
const tokenHolders = ref<any[]>([])

const generalSorting = [
  { name: 'All', value: 'all' },
  { name: '24 Hours', value: '24h' },
  { name: '7 Days', value: '7d' },
  { name: '30 Days', value: '30d' },
  { name: '90 Days', value: '90d' }
]

const currentSort = ref({ name: 'Please select a period', value: 'all' })

const fetchWithDateFilter = async () => {
  let dateFrom = '2020-01-01T00:00:00.000Z'
  let dateTo = new Date().toISOString()

  // Set the date range based on selectedPeriod value
  switch (selectedPeriod.value) {
    case '':
      dateFrom = '2020-01-01T00:00:00.000Z'
      dateTo = new Date().toISOString()
      break
    case 'all':
      dateFrom = '2020-01-01T00:00:00.000Z'
      dateTo = new Date().toISOString()
      break
    case '24h':
      dateFrom = new Date(Date.now() - (24 * 60 * 60 * 1000)).toISOString()
      dateTo = new Date().toISOString()
      break
    case '7d':
      dateFrom = new Date(Date.now() - (7 * 24 * 60 * 60 * 1000)).toISOString()
      dateTo = new Date().toISOString()
      break
    case '30d':
      dateFrom = new Date(Date.now() - (30 * 24 * 60 * 60 * 1000)).toISOString()
      dateTo = new Date().toISOString()
      break
    case '90d':
      dateFrom = new Date(Date.now() - (90 * 24 * 60 * 60 * 1000)).toISOString()
      dateTo = new Date().toISOString()
      break
    default:
      // Fetch all data
      dateFrom = '2020-01-01T00:00:00.000Z'
      dateTo = new Date().toISOString()
      break
  }

  try {
    // Fetch saleStats and recent sales data
    const statsApiUrl = `${apiUrl}/playermon-sale-history/getSaleStatistics?dateFrom=${encodeURIComponent(dateFrom)}&dateTo=${encodeURIComponent(dateTo)}&period=${encodeURIComponent(selectedPeriod.value)}`
    const recentSalesApiUrl = `${apiUrl}/marketplace-v3/getRecentSold?page=${encodeURIComponent(page.value)}&limit=${encodeURIComponent(limit.value)}&keyword=${encodeURIComponent(keyword.value)}`

    const [saleStatsResponse, recentSalesResponse] = await Promise.all([
      fetch(statsApiUrl),
      fetch(recentSalesApiUrl)
    ])

    const saleStatsData = await saleStatsResponse.json()
    const recentSalesData = await recentSalesResponse.json()

    if (saleStatsData.data.length === 0) {
      // Set the values to 0 if saleStatsData is empty
      volume.value = '0 PYM'
      totalSales.value = '0'
    } else {
      const { trading_volume, sales } = saleStatsData.data[0]
      volume.value = `${trading_volume.toFixed(2)} PYM`
      totalSales.value = `${sales}`
    }

    const filteredSales = recentSalesData.data.docs.filter((sale: { timestamp: number }) =>
      sale.timestamp >= Date.parse(dateFrom) && sale.timestamp <= Date.parse(dateTo)
    )

    // Fetch playermon data for each sale
    const playermonDataPromises = filteredSales.map((sale: { token_id: string }) =>
      fetch(`https://metadata.playermon.com/playermon/${sale.token_id}`)
    )

    const playermonResponses = await Promise.all(playermonDataPromises)
    const playermonData = await Promise.all(playermonResponses.map(response => response.json()))

    const formattedSales = filteredSales.map((sale: any, index: number) => {
      const { image } = playermonData[index]
      const formattedImage = image.replace('ipfs://', 'https://download.playermon.com/playermons/') +
    '.png'

      return {
        ...sale,
        formattedImage
      }
    })

    recentSales.value = formattedSales.length === 0 ? [] : formattedSales
  } catch (error) {
    console.error('Error fetching saleStats and recent sales:', error) // eslint-disable-line no-console
  }
}

const fetchWithoutFilter = async () => {
  try {
    const dateFrom = '2022-01-01T00:00:00.000Z'
    const dateTo = new Date().toISOString()

    // Fetch token holders data
    const tokenHoldersApiUrl = `${apiUrl}/marketplace/getTokenHolders`
    const floorPriceUrl = `${apiUrl}/marketplace/getPlayermonsFloorPrice?dateFrom=${encodeURIComponent(dateFrom)}&dateTo=${encodeURIComponent(dateTo)}`

    const tokenHoldersResponse = await fetch(tokenHoldersApiUrl)
    const floorPriceResponse = await fetch(floorPriceUrl)

    const tokenHoldersData = await tokenHoldersResponse.json()
    const floorPriceData = await floorPriceResponse.json()

    const { floor_price } = floorPriceData.data[0]
    floorPrice.value = `${floor_price.toFixed(2)} PYM`

    tokenHolders.value = tokenHoldersData.data.token_holder.holders_with_percentage
    totalSupply.value = tokenHoldersData.data.token_holder.total_supply
  } catch (error) {
    console.error('Error fetching token holders:', error) // eslint-disable-line no-console
  }
}

const fetchTokenHolders = async () => {
  const tokenHoldersUrl = `${apiUrl}/marketplace/getTokenHolders`

  try {
    const [tokenHoldersResponse] = await Promise.all([
      fetch(tokenHoldersUrl)
    ])

    const tokenHoldersData = await tokenHoldersResponse.json()

    const { total_owners, percentage_unique_owners } = tokenHoldersData.data.token_holder
    totalOwners.value = `${total_owners}`
    uniqueOwnersPercentage.value = `${Math.round(percentage_unique_owners)}%`
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error:', error)
  }
}

const { data: recentPlayermonList, pending: pendingRecentPlayermon } = await useFetch<MarketplaceResponseApi>(
  () =>
    `${apiUrl}/marketplace?version=1&page=1&limit=10&sort=latest&stages=Adult&stage=Both`,
  { server: false }
)

// Format the timestamp to "DD Month Year HH:MM:SS" format
const formatDate = (timestamp: string) => {
  const date = new Date(parseInt(timestamp))
  const formatter = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  })
  return formatter.format(date)
}

const formatTime = (timestamp: string) => {
  const date = new Date(parseInt(timestamp))
  const formatter = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit'
  })
  return formatter.format(date)
}

const calculateDaysAgo = (timestamp: string): string => {
  const saleDate = new Date(Number(timestamp))
  const currentDate = new Date()

  const timeDiff = Math.abs(currentDate.getTime() - saleDate.getTime())
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))

  return `${daysDiff} day${daysDiff !== 1 ? 's' : ''} ago`
}

const getPriceDifference = (currentPrice: string | null, floorPrice: string | null) => {
  if (currentPrice !== null && floorPrice !== null) {
    const currentPriceNum = parseFloat(currentPrice)
    const floorPriceNum = parseFloat(floorPrice)

    if (!isNaN(currentPriceNum) && !isNaN(floorPriceNum)) {
      if (currentPriceNum > floorPriceNum) {
        const percentageDifference = ((currentPriceNum - floorPriceNum) / floorPriceNum) * 100
        return `+${percentageDifference.toFixed(2)}% at floor`
      } else if (currentPriceNum < floorPriceNum) {
        const percentageDifference = ((floorPriceNum - currentPriceNum) / floorPriceNum) * 100
        return `-${percentageDifference.toFixed(2)}% at floor`
      } else {
        return 'At floor 0%'
      }
    }
  }

  return 'Data unavailable'
}

// Call the fetch functions on component mount
const fetchData = async () => {
  await fetchWithDateFilter()
  await fetchWithoutFilter()
  await fetchTokenHolders()
}

const getImageUrl = (image: string) => {
  let formattedImage = ''
  formattedImage = image.replace('ipfs://', `${imageUrl}`) + '.png'
  return formattedImage
}

// Watch for changes in selectedPeriod, page, limit, and keyword, and call the fetch functions accordingly
watch([selectedPeriod, page, limit, keyword], async () => {
  await fetchWithDateFilter()
  await fetchWithoutFilter()
})

onMounted(() => {
  fetchData()
})

</script>

<template>
  <div class="mx-10">
    <div class="flex justify-end mb-16">
      <div class="text-white">
        <label for="period" class="mb-2 text-sm font-medium text-white">
          Filter by Period:
        </label>
        <Menu as="div" class="relative text-left">
          <MenuButton
            class="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white bg-gray-600 rounded-md hover:bg-opacity-30"
          >
            {{ currentSort.name }}
            <ChevronDownIcon
              class="w-5 h-5 ml-2 -mr-1 text-white hover:text-violet-100"
              aria-hidden="true"
            />
          </MenuButton>

          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <MenuItems
              class="absolute right-0 mt-2 w-40 rounded-md bg-[#1e0830] focus:outline-none"
            >
              <MenuItem v-for="sort in generalSorting" :key="sort.value">
                <span
                  class="group flex items-center w-full rounded-md px-2 py-2 text-sm hover:bg-[#5f159b]"
                  :class="{ 'bg-[#5f159b]': currentSort.value === sort.value }"
                  @click="currentSort = sort; selectedPeriod = sort.value"
                >
                  {{ sort.name }}
                </span>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-4 lg:gap-8 lg:grid-cols-2">
      <div class="grid grid-cols-1 gap-4 lg:gap-8">
        <div class="grid grid-cols-2 gap-4 lg:gap-8">
          <div class="flex-1 px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
            <p class="text-base text-white">
              Total Supply
            </p>
            <p class="text-xl font-bold lg:text-2xl text-sky-400">
              {{ totalSupply }}
            </p>
          </div>
          <div class="flex-1 px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
            <p class="text-base text-white">
              Floor Price
            </p>
            <p class="text-xl font-bold lg:text-2xl text-sky-400">
              {{ floorPrice }}
            </p>
          </div>
          <div class="flex-1 px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
            <p class="text-base text-white">
              Volume
            </p>
            <p class="text-xl font-bold lg:text-2xl text-sky-400">
              {{ volume }}
            </p>
          </div>
          <div class="flex-1 px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
            <p class="text-base text-white">
              Sales
            </p>
            <p class="text-xl font-bold lg:text-2xl text-sky-400">
              {{ totalSales }}
            </p>
          </div>
        </div>
        <div class="block overflow-x-hidden">
          <div class="block border-2 rounded-lg border-sky-500">
            <div class="p-5 text-lg font-semibold text-left rounded-t-lg text-white bg-[#05083a]">
              Recent Sales
              <p class="mt-1 text-sm font-normal text-gray-400">
                Browse the latest sales of Playermon NFTs,
                showcasing the transactions and prices of these unique digital collectibles.
              </p>
            </div>
            <div class="bg-[#05083a] rounded-b-lg">
              <div v-if="recentSales.length > 0" class="overflow-x-hidden max-h-96 rounded-b-lg bg-[#05083a] custom-scrollbar">
                <div v-for="(sale) in recentSales" :key="sale.id" class="bg-[#05083a] h-50 sm:h-50">
                  <div class="w-full pb-2 scale-90 md:w-[650px] lg:w-full lg:mt-5 lg:px-3 sm:mt-2 sm:scale-100">
                    <div class="flex w-full overflow-hidden border-2 rounded-lg shadow-2xl border-sky-500 lg:mt-5 bg-[#0c0f3f]">
                      <div class="bg-[#161A69]">
                        <a :href="`/playermon/${sale.token_id}`" target="_blank" class="text-base">
                          <div class="mt-2 sm:mt-0 lg:w-40 w-28 aspect-w-1 aspect-h-1">
                            <img :src="sale.formattedImage" alt="" class="object-cover w-full h-full scale-75 sm:scale-90">
                          </div>
                        </a>
                      </div>
                      <div class="flex-1 scale-75 lg:mr-10 lg:pl-6 lg:mt-5 md:mt-2 md:ml-4 lg:ml-0 sm:scale-100">
                        <a :href="`/playermon/${sale.token_id}`" target="_blank" class="text-base text-sky-500 hover:text-sky-200">
                          Playermon #{{ sale.token_id }}
                        </a>
                        <a :href="`https://polygonscan.com/tx/${sale.id}`" target="_blank" class="text-sky-500 hover:text-sky-200">
                          <div class="mt-2 sm:mt-14">
                            <p>{{ formatDate(sale.timestamp) }}</p>
                            <p>{{ formatTime(sale.timestamp) }}</p>
                          </div>
                        </a>
                      </div>
                      <div class="flex flex-col pr-6 mt-5 text-xs text-right sm:text-lg">
                        <p class="text-[#FFE182] lg:text-lg sm:text-sm">
                          {{ sale.price }} PYM
                        </p>
                        <p class="mb-2 text-lime-400 sm:mb-0">
                          {{ getPriceDifference(sale.price, floorPrice) }}
                        </p>
                        <p class="text-white lg:mt-12">
                          {{ calculateDaysAgo(sale.timestamp) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="max-h-50">
                <div class="bg-[#05083a]">
                  <div class="px-6 py-4">
                    <p class="py-10 text-base text-center text-white">
                      No results to be displayed
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-4 lg:gap-8">
        <div class="grid grid-cols-2 gap-4 lg:gap-8">
          <div class="flex flex-col px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
            <p class="text-base text-white">
              Total Owners
            </p>
            <p class="text-xl font-bold lg:text-2xl text-sky-400">
              {{ totalOwners }}
            </p>
          </div>
          <div class="flex flex-col px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
            <p class="text-base text-white">
              Unique Owners
            </p>
            <p class="text-xl font-bold lg:text-2xl text-sky-400">
              {{ uniqueOwnersPercentage }}
            </p>
          </div>
        </div>
        <div class="block overflow-x-hidden">
          <div class="block border-2 rounded-lg border-sky-500 overflow:visible">
            <div class="p-5 text-lg font-semibold text-left rounded-t-lg text-white bg-[#05083a]">
              Recent Listings
              <p class="mt-1 text-sm font-normal text-gray-400">
                Browse the latest Playermon NFTs listed,
                showcasing prices of these unique digital collectibles on marketplace.
              </p>
            </div>
            <div class="bg-[#05083a] rounded-b-lg">
              <div v-if="!pendingRecentPlayermon" class="overflow-x-hidden max-h-[500px] rounded-b-lg bg-[#05083a] custom-scrollbar">
                <div v-for="listing in recentPlayermonList?.data?.marketplace_items" :key="listing.playermon_id" class="bg-[#05083a] h-50 sm:h-50">
                  <div class="w-full pb-2 scale-90 md:w-[650px] lg:w-full lg:mt-5 lg:px-3 sm:mt-2 sm:scale-100">
                    <div class="flex w-full overflow-hidden border-2 rounded-lg shadow-2xl border-sky-500 lg:mt-5 bg-[#0c0f3f]">
                      <div class="bg-[#161A69]">
                        <a :href="`/playermon/${listing.playermon_id}`" target="_blank" class="text-base">
                          <div class="mt-2 sm:mt-0 lg:w-40 w-28 aspect-w-1 aspect-h-1">
                            <img :src="getImageUrl(listing.image)" alt="" class="object-cover w-full h-full scale-75 sm:scale-90">
                          </div>
                        </a>
                      </div>
                      <div class="flex-1 scale-75 lg:mr-10 lg:pl-6 lg:mt-5 md:mt-2 md:ml-4 lg:ml-0 sm:scale-100">
                        <a :href="`/playermon/${listing.playermon_id}`" target="_blank" class="text-base text-sky-500 hover:text-sky-200">
                          Playermon #{{ listing.playermon_id }}
                        </a>
                        <div v-if="listing.timestamp" class="mt-2 text-white sm:mt-14">
                          <p>{{ formatDate(String(listing.timestamp)) }}</p>
                          <p>{{ formatTime(String(listing.timestamp)) }}</p>
                        </div>
                      </div>
                      <div class="flex flex-col pr-6 mt-5 text-xs text-right sm:text-lg">
                        <p class="text-[#FFE182] lg:text-lg sm:text-sm">
                          {{ listing.price }} PYM
                        </p>
                        <p class="mb-2 text-lime-400 sm:mb-0">
                          {{ getPriceDifference(listing.price.toString(), floorPrice) }}
                        </p>
                        <p v-if="listing.timestamp" class="text-white lg:mt-12">
                          {{ calculateDaysAgo(String(listing.timestamp)) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="max-h-50">
                <div class="bg-[#05083a]">
                  <div class="px-6 py-4">
                    <p class="py-10 text-base text-center text-white">
                      No results to be displayed
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="flex flex-wrap gap-8 md:flex-nowrap">
      <div class="flex-1 px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
        <p class="text-base text-white">
          Total Supply
        </p>
        <p class="text-2xl font-bold text-sky-400">
          {{ totalSupply }}
        </p>
      </div>
      <div class="flex-1 px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
        <p class="text-base text-white">
          Floor Price
        </p>
        <p class="text-2xl font-bold text-sky-400">
          {{ floorPrice }}
        </p>
      </div>
      <div class="flex-1 px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
        <p class="text-base text-white">
          Owners
        </p>
        <p class="text-2xl font-bold text-sky-400">
          {{ totalOwners }}
        </p>
      </div>
      <div class="flex-1 px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
        <p class="text-base text-white">
          Unique Owners
        </p>
        <p class="text-2xl font-bold text-sky-400">
          {{ uniqueOwnersPercentage }}
        </p>
      </div>
    </div>

    <div class="mt-8">
      <div class="grid grid-cols-1 gap-8 md:grid-cols-2">
        <div class="block overflow-x-hidden">
          <div class="block border-2 rounded-lg border-sky-500 overflow:visible">
            <div class="p-5 text-lg font-semibold text-left rounded-t-lg text-white bg-[#05083a]">
              Recent Sales
              <p class="mt-1 text-sm font-normal text-gray-400">
                Browse the latest sales of Playermon NFTs,
                showcasing the transactions and prices of these unique digital collectibles.
              </p>
            </div>
            <div class="bg-[#05083a] rounded-b-lg">
              <div v-if="recentSales.length > 0" class="overflow-x-hidden max-h-96 rounded-b-lg bg-[#05083a] custom-scrollbar">
                <div v-for="(sale) in recentSales" :key="sale.id" class="bg-[#05083a] h-50 sm:h-50">
                  <div class="w-full pb-2 scale-90 lg:mt-5 lg:px-3 sm:mt-2 sm:scale-100">
                    <div class="flex w-full overflow-hidden border-2 rounded-lg shadow-2xl border-sky-500 lg:mt-5 bg-[#0c0f3f]">
                      <div class="bg-[#161A69]">
                        <a :href="`/playermon/${sale.token_id}`" target="_blank" class="text-base">
                          <div class="mt-2 sm:mt-0 lg:w-40 w-28 aspect-w-1 aspect-h-1">
                            <img :src="sale.formattedImage" alt="" class="object-cover w-full h-full scale-75 sm:scale-90">
                          </div>
                        </a>
                      </div>
                      <div class="flex-1 scale-75 lg:mr-10 lg:pl-6 lg:mt-5 sm:scale-100">
                        <a :href="`/playermon/${sale.token_id}`" target="_blank" class="text-base text-sky-500 hover:text-sky-200">
                          Playermon #{{ sale.token_id }}
                        </a>
                        <a :href="`https://polygonscan.com/tx/${sale.id}`" target="_blank" class="text-sky-500 hover:text-sky-200">
                          <div class="mt-2 sm:mt-14">
                            <p>{{ formatDate(sale.timestamp) }}</p>
                            <p>{{ formatTime(sale.timestamp) }}</p>
                          </div>
                        </a>
                      </div>
                      <div class="flex flex-col pr-6 mt-5 text-xs text-right sm:text-lg">
                        <p class="text-[#FFE182] lg:text-lg sm:text-sm">
                          {{ sale.price }} PYM
                        </p>
                        <p class="mb-2 text-lime-400 sm:mb-0">
                          {{ getPriceDifference(sale.price, floorPrice) }}
                        </p>
                        <p class="text-white lg:mt-12">
                          {{ calculateDaysAgo(sale.timestamp) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="max-h-50">
                <div class="bg-[#05083a]">
                  <div class="px-6 py-4">
                    <p class="py-10 text-base text-center text-white">
                      No results to be displayed
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block overflow-x-hidden">
          <div class="block border-2 rounded-lg border-sky-500 overflow:visible">
            <div class="p-5 text-lg font-semibold text-left   rounded-t-lg text-white bg-[#05083a]">
              Owners
              <p class="mt-1 text-sm font-normal text-gray-400">
                Top 100 Playermon Owners
              </p>
            </div>
            <div class="bg-[#0a0e74]">
              <div class="block py-3 text-center">
                <div class="grid grid-cols-4">
                  <div class="w-full mt-3 text-white scale-75 sm:mt-0 sm:scale-100">
                    Rankings
                  </div>
                  <div class="w-full mt-3 text-white scale-75 sm:mt-0 sm:scale-100">
                    User
                  </div>
                  <div class="w-full mt-3 text-white scale-75 sm:mt-0 sm:scale-100">
                    Owned
                  </div>
                  <div class="w-full text-white scale-75 sm:scale-100">
                    Percent Owned
                  </div>
                </div>
              </div>
            </div>
            <div class="rounded-b-lg bg-[#05083a]">
              <div class="block py-3 overflow-y-auto text-center max-h-96 custom-scrollbar">
                <div v-if="tokenHolders.length > 0">
                  <div v-for="(holder, index) in tokenHolders" :key="holder.address">
                    <div class="grid grid-cols-4 pt-3">
                      <div class="w-full text-gray-400">
                        {{ index + 1 }}
                      </div>
                      <div class="w-full overflow-x-hidden text-sky-500 hover:text-white">
                        <NuxtLink
                          :to="`/profile/${holder.address}`"
                          target="_blank"
                          class="relative"
                          :title="holder.address"
                        >
                          {{ holder.address.substring(0, 6) }}...........{{ holder.address.substring(holder.address.length - 4) }}
                        </NuxtLink>
                      </div>
                      <div class="w-full text-gray-400">
                        {{ holder.balance }}
                      </div>
                      <div class="w-full text-gray-400">
                        {{ holder.percent_ownership.toFixed(2) }}%
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="max-h-50">
                  <div class="bg-[#05083a]">
                    <div class="px-6 py-4">
                      <p class="py-10 text-base text-center text-white">
                        No results to be displayed
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
